<template lang="pug">
.editorContainer
  textarea(:id="editorId" class="textareaContainer")

</template>

<script setup>
import { inject, onMounted, ref, watch, onActivated } from 'vue'
import { nanoid } from 'nanoid'
import './lib/tinymce.min.js'
import './icons/default/icons.min.js'
import { uploadImage, dataURLtoBlob } from '@/provider/upload'
const { fetch } = inject('global')

let editorInstance = ref(null)
const editorId = `textareaContainer-${nanoid(10)}`
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  type: {
    type: String,
    derfault: 'normal'
  },
  modelValue: {
    type: String,
    default: ''
  },
  uploadType: {
    type: String,
    default: 'commodity'
  },
  height: {
    type: [String, Number],
    default: 300
  },
  plugins: {
    type: String,
    default: ''
  },
  appendToolsBar: {
    type: String,
    default: ''
  },
  imageDimensions: {
    type: Boolean,
    default: false
  }
})

const baseConfig = {
  height: Number(props.height),
  selector: `#${editorId}`,
  theme_url: '//static.tanchi.shop/plugin/tinymce_5.10/themes/silver/theme.min.js',
  skin_url: '//static.tanchi.shop/plugin/tinymce/skins/ui/boom',
  content_css: '//static.tanchi.shop/plugin/tinymce/skins/content/boom/content.min.css',
  language_url: '//static.tanchi.shop/plugin/tinymce_5.10/langs/zh_CN.js',
  language: 'zh_CN',
  content_style: 'img {max-width:100%;}',
  branding: false,
  contextmenu_never_use_native: true,
  elementpath: false,
  init_instance_callback: val => {
    editorInstance.value = val
    editorInstance.value.setContent(props.modelValue)
  },
  setup: editor => {
    editor.on('change', () => {
      const val = editor.getContent()
      emits('update:modelValue', val)
    })
    editor.on('input', () => {
      const val = editor.getContent()
      emits('update:modelValue', val)
    })
    editor.on('click', () => {
      // console.log('Editor was clicked')
    })
  }
}
const font_formats =
  '苹方=PingFangSC,sans-serif; 雅黑=Microsoft Yahei; 黑体=SimHei; 宋体=SimSun; 仿宋=FangSong; 幼圆=YouYuan; 隶书=LiSu; 华文黑体=STHeiti; 华文细黑=STXihei;  华文楷体=STKaiti; 华文宋体=STSong; 华文仿宋=STFangsong 华文彩云=华文彩云; 华文行楷=华文行楷; 华文新魏=STXinwei; 方正舒体=FZShuTi; 方正姚体=FZYaoti'
const configForSimple = {
  menubar: false,
  statusbar: false,
  image_dimensions: props.imageDimensions || false,
  external_plugins: {}
}
const configForNormal = {
  min_height: 300,
  menubar: false,
  paste_auto_cleanup_on_paste: false,
  paste_remove_styles: false,
  paste_remove_styles_if_webkit: false,
  paste_strip_class_attributes: false,
  paste_data_images: true,
  powerpaste_word_import: 'clean',
  end_container_on_empty_block: true,
  code_dialog_height: 450,
  code_dialog_width: 1000,
  advlist_bullet_styles: 'square',
  advlist_number_styles: 'default',
  default_link_target: '_blank',
  link_title: false,
  nonbreaking_force_tab: true,
  convert_urls: false,
  object_resizing: true,
  toolbar_mode: 'wrap',
  font_formats,
  toolbar:
    'undo redo | forecolor backcolor casechange permanentpen formatpainter removeformat | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | pagebreak | numlist bullist checklist | image link  | fontselect fontsizeselect |  code ' +
    props.appendToolsBar,
  external_plugins: {
    pagebreak: '//static.tanchi.shop/plugin/tinymce/pagebreak.js',
    paste: '//static.tanchi.shop/plugin/tinymce/paste.js',
    code: '//static.tanchi.shop/plugin/tinymce/code.js',
    lists: '//static.tanchi.shop/plugin/tinymce/lists.js',
    image: '//static.tanchi.shop/plugin/tinymce/image.js',
    link: '//static.tanchi.shop/plugin/tinymce/link.js',
    imagetools: '//static.tanchi.shop/plugin/tinymce/imagetools.js'
    // restoreImage
  },
  plugins: props.plugins,
  images_upload_handler: (blobInfo, succFun, failFun) => {
    let file = blobInfo.blob()
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = e => {
      let blob = dataURLtoBlob(e.currentTarget.result)
      uploadImage({ file: blob, type: props.uploadType })
        .then(res => {
          succFun(res)
        })
        .catch(err => {
          console.log('>>>>>>> err', err)
          failFun('上传失败')
        })
    }
  },
  pickImageList_handler: (success, fail) => {
    window.$globalUtil
      .pickImageList({ type: 'commodity', count: 9 })
      .then(res => {
        success(res)
      })
      .catch(err => {
        fail(err)
      })
  },
  fetch
}
const configForFull = {
  min_height: 500,
  toolbar_mode: 'wrap',
  menubar: 'file edit insert view format table tools boom',
  menu: {
    insert: {
      title: '插入',
      items:
        'image batchUploadImage link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
    },
    tools: { title: '工具', items: 'code wordcount |  ' },
    boom: { title: '重点推介功能', items: ' batchUploadImage' }
  },
  toolbar:
    'undo redo | forecolor backcolor casechange permanentpen formatpainter removeformat | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | pagebreak | numlist bullist checklist | image media link | anchor codesample template code | fontselect fontsizeselect | batchUploadImage ' +
    props.appendToolsBar,
  plugins: props.plugins,
  external_plugins: {
    advlist: '//static.tanchi.shop/plugin/tinymce/advlist.js',
    anchor: '//static.tanchi.shop/plugin/tinymce/anchor.js',
    autolink: '//static.tanchi.shop/plugin/tinymce/autolink.js',
    autosave: '//static.tanchi.shop/plugin/tinymce/autosave.js',
    codesample: '//static.tanchi.shop/plugin/tinymce/codesample.js',
    directionality: '//static.tanchi.shop/plugin/tinymce/directionality.js',
    hr: '//static.tanchi.shop/plugin/tinymce/hr.js',
    image: '//static.tanchi.shop/plugin/tinymce/image.js',
    imagetools: '//static.tanchi.shop/plugin/tinymce/imagetools.js',
    insertdatetime: '//static.tanchi.shop/plugin/tinymce/insertdatetime.js',
    link: '//static.tanchi.shop/plugin/tinymce/link.js',
    code: '//static.tanchi.shop/plugin/tinymce/code.js',
    pagebreak: '//static.tanchi.shop/plugin/tinymce/pagebreak.js',
    lists: '//static.tanchi.shop/plugin/tinymce/lists.js',
    media: '//static.tanchi.shop/plugin/tinymce/media.js',
    nonbreaking: '//static.tanchi.shop/plugin/tinymce/nonbreaking.js',
    noneditable: '//static.tanchi.shop/plugin/tinymce/noneditable.js',
    paste: '//static.tanchi.shop/plugin/tinymce/paste.js',
    preview: '//static.tanchi.shop/plugin/tinymce/preview.js',
    searchreplace: '//static.tanchi.shop/plugin/tinymce/searchreplace.js',
    tabfocus: '//static.tanchi.shop/plugin/tinymce/tabfocus.js',
    table: '//static.tanchi.shop/plugin/tinymce/table.js',
    template: '//static.tanchi.shop/plugin/tinymce/template.js',
    textpattern: '//static.tanchi.shop/plugin/tinymce/textpattern.js',
    visualblocks: '//static.tanchi.shop/plugin/tinymce/visualblocks.js',
    visualchars: '//static.tanchi.shop/plugin/tinymce/visualchars.js',
    wordcount: '//static.tanchi.shop/plugin/tinymce/wordcount.js'
  }
}

onMounted(() => {
  let configpayload = { ...baseConfig }
  if (props.type === 'simple') {
    configpayload = { ...configpayload, ...configForSimple }
  } else if (props.type === 'full') {
    configpayload = { ...configpayload, ...configForNormal, ...configForFull }
  } else {
    configpayload = { ...configpayload, ...configForNormal }
  }
  tinymce.init(configpayload)
})
onActivated(() => {
  editorInstance.value.setContent(props.modelValue)
})
watch(
  () => props.modelValue,
  val => {
    if (editorInstance?.value) {
      const content = editorInstance.value.getContent()
      if (val !== content) {
        editorInstance.value.setContent(props.modelValue)
      }
    }
  }
)
</script>

<style>
.editorContainer {
  min-width: 300px;
}
</style>
